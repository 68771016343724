<template>
	<div v-scroll="handleScroll" v-if="allowed" style="overflow: auto">
		<v-overlay :class="{ mobile: mobile }" :style="navStyle" :value="mobile && active"></v-overlay>
		<v-navigation-drawer mini-variant-width="90" color="#072d52" height="100%" app v-model="active" hide-overlay
			disable-resize-watcher :style="drawerStyle">
			<div v-if="user && loggedIn" style="width: 100%; margin-top: 5px; margin-bottom: 5px;"
				@click="openImageDialog('portrait')">
				<v-overlay absolute v-if="profilePictureUploading">
					Uploading Profile Picture
					<v-progress-circular indeterminate color="amber"></v-progress-circular>
				</v-overlay>
				<div style="display:flex; justify-content: center;align-items: center; height: 250px;"
					v-if="user.profilePicture?.path">
					<BrebFile :file="user.profilePicture" ratio="photo" />
				</div>
				<div style="display: flex; justify-content: center;" v-else>
					<v-icon size="150" class="outlined-box">
						mdi-account
					</v-icon>
				</div>
			</div>
			<!-- <div v-if="user && loggedIn">
				<v-file-input style="display: none;" ref="fileSelect" @change="uploadProfilePicture" />
			</div> -->
			<div class="flex flex-wrap align-content-center justify-center welcome-background" v-if="user && loggedIn">
				<div style="width: 100%; text-align: center; color: white;" v-if="loggedIn">
					<h3 style="text-decoration: underline">Welcome to <span style="color: yellow">MyBREB</span></h3>
				</div>
				<div style="width: 100%; text-align: center; color: white;" v-if="loggedIn">
					{{ user.firstName }}
				</div>
			</div>

			<v-card dark flat color="#072d52" class="breb-custom-scroll">
				<v-list dark class="pt-0 list-item-white">

					<v-list-item color="white" to="/dashboard/login" v-if="!loggedIn">
						<v-list-item-icon>
							<v-icon size="24">login</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>
								Sign In
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>

					<!-- Home -->
					<v-list-item color="white" to="/" style="border-top-width: 1px;">
						<v-list-item-icon>
							<v-icon size="24">other_houses</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>
								Home
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>

					<!-- About -->
					<v-list-item color="white" to="/about" style="border-top-width: 1px;">
						<v-list-item-icon>
							<v-icon size="24">info</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>
								About
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>

					<!-- <v-list-item v-if="loggedIn && hasPartnerCreate && !partnerConnected"
						@click="navigateStripeOauthInit" style="border-top-width: 1px;">
						<v-list-item-icon>
							<v-icon size="24" color="yellow">mdi-currency-usd</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title style="color: yellow">Connect to Stripe</v-list-item-title>
						</v-list-item-content>
					</v-list-item> -->

					<v-list-item v-if="!loggedIn" color="white" to="/subscriptions" style="border-top-width: 1px;">
						<v-list-item-icon>
							<v-icon size="24">other_houses</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>
								Become a Merchant
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>

					<!-- Profile -->
					<v-list-item color="white" v-if="user && loggedIn" to="/dashboard/profile">
						<v-list-item-icon>
							<v-icon size="24">account_circle</v-icon>
						</v-list-item-icon>
						<v-list-item-title>
							MyBREB Account
						</v-list-item-title>
					</v-list-item>

					<!-- Realtor Profile -->
					<v-list-item color="white" @click="navigateToRealtorProfile" style="border-top-width: 1px;">
						<v-list-item-icon>
							<v-icon size="24">mdi-card-account-details-outline</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title style="font-size: 14px;">
								<span style="color: yellow;">FREE</span>
								Realtor Profile
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>

					<!-- Brokerage Profile -->
					<v-list-item color="white" @click="navigateToBrokerageIntroduction" style="border-top-width: 1px;">
						<v-list-item-icon>
							<v-icon size="24">mdi-account-group</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title style="font-size: 14px;">
								<span style="color: yellow;">FREE</span>
								Brokerage Profile
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>

					<!-- Brokerage Profile -->
					<v-list-item color="white" @click="navigateToCityProfileIntroduction"
						style="border-top-width: 1px;">
						<v-list-item-icon>
							<v-icon size="24">mdi-home-city</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title style="font-size: 14px;">
								<span style="color: yellow">MyCity</span>
								Profile & Directory
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>

					<!-- My favourites -->
					<v-list-item style="position: relative" to="/dashboard/bookmarks">
						<v-list-item-icon>
							<v-icon size="24">mdi-cards-heart</v-icon>
						</v-list-item-icon>
						<v-list-item-title>
							Favourites
						</v-list-item-title>
					</v-list-item>

					<!-- Notifications -->
					<!-- <v-list-item style="position: relative" to="/dashboard/notifications/list">
						<v-list-item-icon>
							<v-icon size="24">mdi-message-badge</v-icon>
						</v-list-item-icon>
						<v-list-item-title>Notifications</v-list-item-title>
					</v-list-item> -->

					<!-- Alerts -->
					<v-list-item style="position: relative" to="/dashboard/alerts">
						<v-list-item-icon>
							<v-icon size="24">mdi-bell</v-icon>
						</v-list-item-icon>
						<v-list-item-title style="position:relative; overflow: visible; padding-top: 15px;">
							<v-badge :value="matchedNumber" color="red" class="pa-0 ma-0" style="top: -8px;">
								<template v-slot:badge>
									{{ matchedNumber > 9 ? '9+' : matchedNumber }}
								</template>
								<span>
									Alerts
								</span>
							</v-badge>
						</v-list-item-title>
					</v-list-item>

					<!-- Marketplace -->
					<v-list-group v-if="user && loggedIn" no-action class="row-fix list-item-white-bordered"
						color="white" group="/dashboard/marketplace">
						<template v-slot:prependIcon style="margin-right: 2px">
							<v-icon size="24">mdi-shopping</v-icon>
						</template>
						<template v-slot:activator>
							<v-list-item-content>
								<v-list-item-title>
									Marketplace
								</v-list-item-title>
							</v-list-item-content>
						</template>
						<!-- Marketplace registration -->
						<v-list-item color="white" to="/dashboard/marketplace/registration" class="list-subitem"
							exact-path>
							<v-list-item-icon class="mr-5">
								<v-icon size="18">mdi-store-plus</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title style="font-size: 14px">
									Become A Seller
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<!-- Shopping Cart -->
						<v-list-item color="white" to="/dashboard/marketplace/cart" class="list-subitem" exact-path>
							<v-list-item-icon class="mr-5">
								<v-icon size="18">shopping_cart</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title style="font-size: 14px">
									MyShopping Cart
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<!-- Order -->
						<v-list-item color="white" to="/dashboard/marketplace/orders" class="list-subitem" exact-path>
							<v-list-item-icon class="mr-5">
								<v-icon size="18">mdi-order-bool-ascending</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title style="font-size: 14px">
									MyPurchase Orders
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<!-- Shops -->
						<v-list-group v-if="shops.length" no-action sub-group group="/dashboard/marketplace/shops"
							style="background-color: #0b4073;" color="white" class="shops-group" prepend-icon="">
							<template v-slot:prependIcon>
								<v-icon size="18">mdi-store</v-icon>
							</template>
							<template v-slot:appendIcon>
								<v-icon size="18">keyboard_arrow_down</v-icon>
							</template>
							<template v-slot:activator>
								<v-list-item-content>
									<v-list-item-title style="font-size: 14px">
										MyShops
									</v-list-item-title>
								</v-list-item-content>
							</template>

							<!-- Shop list-->
							<v-list-group no-action sub-group :group="`/dashboard/marketplace/shops/shop/${shop.id}/*`"
								style="background-color: #5d8cb9;" color="white" v-for="(shop, index) in shops"
								:key="shop.id" class="shop-group" prepend-icon="">
								<template v-slot:appendIcon style="margin-right: 2px">
									<v-icon size="18">keyboard_arrow_down</v-icon>
								</template>
								<template v-slot:activator>
									<v-list-item-content>
										<v-list-item-title style="font-size: 14px">
											{{ shop.name }}
										</v-list-item-title>
									</v-list-item-content>
								</template>
								<v-list-item color="white" @click="toShopSection('summary', shop)"
									style="background-color: #4695ed;" exact-path>
									<v-list-item-icon class="mr-3">
										<v-icon size="18">mdi-store-settings</v-icon>
									</v-list-item-icon>
									<v-list-item-content>
										<v-list-item-title style="font-size: 14px">
											MyShop Summary
										</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
								<v-list-item color="white" @click="toShopSection('invoices', shop)"
									style="background-color: #4695ed;" exact-path>
									<v-list-item-icon class="mr-3">
										<v-icon size="18">mdi-invoice-multiple</v-icon>
									</v-list-item-icon>
									<v-list-item-content>
										<v-list-item-title style="font-size: 14px">
											MyShop Invoices
										</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
								<v-list-item color="white" @click="toShopSection('products', shop)"
									style="background-color: #4695ed;" exact-path>
									<v-list-item-icon class="mr-3">
										<v-icon size="18">mdi-list-box-outline</v-icon>
									</v-list-item-icon>
									<v-list-item-content>
										<v-list-item-title style="font-size: 14px">
											MyShop Products
										</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
								<v-list-item color="white" @click="toShopSection('setting', shop)"
									style="background-color: #4695ed;" exact-path>
									<v-list-item-icon class="mr-3">
										<v-icon size="18">mdi-store-cog-outline</v-icon>
									</v-list-item-icon>
									<v-list-item-content>
										<v-list-item-title style="font-size: 14px">
											MyShop Settings
										</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
							</v-list-group>
						</v-list-group>
					</v-list-group>

					<!-- Listings -->
					<v-list-group no-action class="row-fix list-item-white-bordered" color="white"
						group="/dashboard/listing">
						<template v-slot:prependIcon style="margin-right: 2px">
							<v-icon size="24">mdi-view-list-outline</v-icon>
						</template>
						<template v-slot:activator>
							<v-list-item-content>
								<v-list-item-title>
									Listings
								</v-list-item-title>
							</v-list-item-content>
						</template>

						<v-list-item v-if="user && loggedIn" color="white" to="/dashboard/listings" class="list-subitem"
							exact-path>
							<v-list-item-icon class="mr-5">
								<v-icon size="18">mdi-format-list-group</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title style="font-size: 14px">
									MyListings
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>

						<v-list-item color="white" to="/dashboard/listing/post" class="list-subitem">
							<v-list-item-icon class="mr-5">
								<v-icon size="18">mdi-playlist-plus</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title style="font-size: 14px">
									Post <span style="color: yellow">FREE</span> Listing
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list-group>

					<!-- Brokerages -->
					<v-list-group no-action class="row-fix list-item-white-bordered" color="white"
						group="/dashboard/brokerage">
						<template v-slot:prependIcon style="margin-right: 2px">
							<v-icon size="24">mdi-account-group</v-icon>
						</template>
						<template v-slot:activator>
							<v-list-item-content>
								<v-list-item-title>
									Brokerages
								</v-list-item-title>
							</v-list-item-content>
						</template>

						<v-list-item color="white" to="/search/brokerage" class="list-subitem" exact-path>
							<v-list-item-icon class="mr-5">
								<v-icon size="18">mdi-account-search</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title style="font-size: 14px">
									Find Brokerages
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>

						<v-list-item v-if="user && loggedIn" color="white" to="/dashboard/brokerage/list"
							class="list-subitem" exact-path>
							<v-list-item-icon class="mr-5">
								<v-icon size="18">mdi-account-multiple-plus</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title style="font-size: 14px">
									MyBrokerages
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list-group>

					<!-- Notifications -->
					<v-list-group v-if="loggedIn" no-action class="row-fix list-item-white-bordered" color="white"
						group="/dashboard/notifications">
						<template v-slot:prependIcon style="margin-right: 2px">
							<v-icon size="24">mdi-forum-outline</v-icon>
						</template>
						<template v-slot:activator>
							<v-list-item-content>
								<v-list-item-title style="position:relative; overflow: visible; padding-top: 15px;">
									<v-badge :value="notificationsUnread" color="red" class="pa-0 ma-0"
										style="top: -8px;">
										<template v-slot:badge>
											{{ notificationsUnread > 9 ? '9+' : notificationsUnread }}
										</template>
										<span>
											Messages
										</span>
									</v-badge>
								</v-list-item-title>
							</v-list-item-content>
						</template>

						<v-list-item color="white" to="/dashboard/notifications/list" class="list-subitem" exact-path>
							<v-list-item-icon class="mr-5">
								<v-icon size="18">mdi-message-badge</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title
									style="font-size: 14px; position:relative; overflow: visible; padding-top: 15px;">
									<v-badge :value="notificationsUnread" color="red" class="pa-0 ma-0"
										style="top: -10px;">
										<template v-slot:badge>
											{{ notificationsUnread > 9 ? '9+' : notificationsUnread }}
										</template>
										<span>
											MyCHAT
										</span>
									</v-badge>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>

						<v-list-item color="white" to="/dashboard/notifications/ignored" class="list-subitem"
							exact-path>
							<v-list-item-icon class="mr-5">
								<v-icon size="18">app_blocking</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title style="font-size: 14px">
									Blocked Users
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list-group>

					<!-- Partner -->
					<v-list-group v-if="loggedIn && hasPartnerCreate" no-action class="row-fix list-item-white-bordered"
						color="white">
						<template v-slot:prependIcon style="margin-right: 2px">
							<v-icon size="24">mdi-handshake</v-icon>
						</template>
						<template v-slot:activator>
							<v-list-item-content>
								<v-list-item-title>
									Managers
								</v-list-item-title>
							</v-list-item-content>
						</template>

						<v-list-item color="white" to="/dashboard/payment" class="list-subitem">
							<v-list-item-icon class="mr-5">
								<v-icon size="18">mdi-account-credit-card-outline</v-icon>
							</v-list-item-icon>
							<v-list-item-content style="font-size: 14px">
								Payment Processors
							</v-list-item-content>
						</v-list-item>

						<v-list-item color="white" to="/dashboard/manager-account" class="list-subitem" exact-path>
							<v-list-item-icon class="mr-5">
								<v-icon size="18">mdi-ticket-percent</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title style="font-size: 14px">
									Manager Coupons
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>

						<v-list-item color="white" to="/dashboard/manager-invitation" class="list-subitem" exact-path>
							<v-list-item-icon class="mr-5">
								<v-icon size="18">email</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title style="font-size: 14px">
									Invitation Emails
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list-group>

					<!-- User Settings -->
					<v-list-group v-if="loggedIn" no-action class="row-fix list-item-white-bordered" color="white">
						<template v-slot:prependIcon style="margin-right: 2px">
							<v-icon size="24">settings</v-icon>
						</template>
						<template v-slot:activator>
							<v-list-item-content>
								<v-list-item-title>
									Settings
								</v-list-item-title>
							</v-list-item-content>
						</template>

						<v-list-item color="white" to="/dashboard/fileManagement" class="list-subitem" exact-path
							v-if="user">
							<v-list-item-icon class="mr-5">
								<v-icon size="18">mdi-multimedia</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title style="font-size: 14px">
									File Management
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>

						<v-list-item color="white" to="/dashboard/stripe" class="list-subitem" v-if="user && loggedIn">
							<v-list-item-icon class="mr-5">
								<v-icon size="18">mdi-credit-card-outline</v-icon>
							</v-list-item-icon>
							<v-list-item-content style="font-size: 14px">
								Billing
							</v-list-item-content>
						</v-list-item>

						<v-list-item color="white" to="/dashboard/security" class="list-subitem" exact-path v-if="user">
							<v-list-item-icon class="mr-5">
								<v-icon size="18">security</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title style="font-size: 14px">
									Security
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list-group>

					<!-- Administration Zone -->
					<v-list-group v-if="loggedIn && isAdministratorGroup" no-action
						class="row-fix list-item-white-bordered" color="white" group="/dashboard/admin">
						<template v-slot:prependIcon style="margin-right: 2px">
							<v-icon size="24">admin_panel_settings</v-icon>
						</template>
						<template v-slot:activator>
							<v-list-item-content>
								<v-list-item-title>
									Manage BREB
								</v-list-item-title>
							</v-list-item-content>
						</template>

						<v-list-item color="white" to="/dashboard/admin/zones" class="list-subitem" exact-path>
							<v-list-item-icon class="mr-5">
								<v-icon size="18">mdi-advertisements</v-icon>
							</v-list-item-icon>
							<v-list-item-title style="font-size: 14px">
								Advertising
							</v-list-item-title>
						</v-list-item>

						<v-list-item color="white" to="/dashboard/admin/homepage" class="list-subitem" exact-path>
							<v-list-item-icon class="mr-5">
								<v-icon size="18">public</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title style="font-size: 14px">
									Homepage
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>

						<v-list-item color="white" to="/dashboard/admin/users" class="list-subitem" exact-path>
							<v-list-item-icon class="mr-5">
								<v-icon size="24">supervisor_account</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title style="font-size: 14px">
									User Management
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>

						<v-list-item color="white" to="/dashboard/admin/listings" class="list-subitem" exact-path>
							<v-list-item-icon class="mr-5">
								<v-icon size="24">mdi-format-list-bulleted</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title style="font-size: 14px">
									Listing Management
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>

						<!-- <v-list-item color="white" to="/dashboard/admin/stripe" class="list-subitem" exact-path>
							<v-list-item-icon class="mr-5">
								<v-icon size="24">savings</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title style="font-size: 14px">Stripe Settings</v-list-item-title>
							</v-list-item-content>
						</v-list-item> -->

						<v-list-item color="white" to="/dashboard/admin/permissions" class="list-subitem" exact-path>
							<v-list-item-icon class="mr-5">
								<v-icon size="18">security</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title style="font-size: 14px">
									Permissions
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list-group>

					<!--Signin/Signout -->
					<v-list-item color="white" @click="logout" v-if="loggedIn">
						<v-list-item-icon>
							<v-icon size="24">logout</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title style="font-size: 14px">
								Sign Out
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-card>

			<!-- Login/Logout -->
			<template v-slot:append>
				<v-list dark class="pa-0">

				</v-list>
			</template>

		</v-navigation-drawer>
		<v-dialog :width="700" v-if="cropperDialog" v-model="cropperDialog" retain-focus persistent
			transition="dialog-bottom-transition" :fullscreen="$vuetify.breakpoint.xs">
			<v-card>
				<v-card-text class="pa-2">
					<BrebCropper :title="cropper.title" :uploadType="cropper.type" @closeCopper="closeCopper"
						@uploadSuccess="uploadSuccess" />
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import BrebCropper from "@/components/accessory/BrebCropper"
import BrebFile from "@/components/accessory/BrebFile.vue";

export default {
	components: {
		BrebCropper,
		BrebFile,
	},
	data: function () {
		return {
			triggered: false,
			navOffset: 0,
			profilePictureUploading: false,
			redirectKey: "redirect-key",
			cropperDialog: false,
			cropper: {
				title: null,
				type: null,
			},
		}
	},
	computed: {
		bookmarksTotalElements() {
			return this.$store.getters["api/bookmarks/getTotalElements"];
		},
		// partnerConnected() {
		// 	return this.$store.getters['user/getStripeAuthenticated'];
		// },
		active: {
			set: function (v) {
				this.triggered = v;
			},
			get: function () {
				if (this.mobile) {
					return this.triggered;
				} else {
					return this.allowed;
				}
			}
		},
		navStyle: function () {
			return {
				top: Math.max(0, this.$vuetify.application.top - this.navOffset) + 'px',
				zIndex: 4
			}
		},
		shops() {
			return this.$store.getters['api/shop/getShops'];
		},
		user: function () {
			return this.$store.getters['user/getUser'];
		},
		loggedIn: function () {
			return this.$store.getters['user/getLoggedIn'];
		},
		isAdministratorGroup() {
			return this.$helpers.isAdministratorGroup(this.$store);
		},
		hasPartnerCreate: function () {
			return this.$helpers.hasPartnerCreate(this.$store);
		},
		drawerStyle: function () {
			return {
				"padding-top": Math.max(0, this.$vuetify.application.top - this.navOffset) + 'px',
				zIndex: 5,
			}
		},
		mobile() {
			return this.$vuetify.breakpoint.mobile;
		},
		allowed() {
			let meta = this.$route.meta;

			// Only named routes are allowed to nav
			if (!this.$route.name) {
				return false;
			}

			// Mobile can navigate from anywhere
			if (this.mobile) {
				return true;
			}

			if (this.$route.path.startsWith('/dashboard')) {
				// Otherwise, static navigation has some restrictions
				if ('navigationAllowed' in meta) {
					return meta.navigationAllowed
				} else {
					return true;
				}
			} else {
				return false;
			}
		},
		// isLive() {
		// 	return window.location.origin === 'https://www.bestrealestatebuy.com';
		// },
		notificationsUnread() {
			return this.$store.getters["api/inbox/getUnreadCount"];
		},
		matchedNumber() {
			return this.$store.getters['api/rule/getHasNewMatched'];
		},
	},
	methods: {
		// navigateStripeOauthInit() {
		// 	localStorage.setItem(
		// 		this.redirectKey,
		// 		JSON.stringify({
		// 			action: "partner_application",
		// 		})
		// 	);
		// 	window.location = `${window.location.origin}/api/v1/stripe/oauth/authorize`
		// },
		navigateToRealtorProfile() {
			if (this.$route.fullPath !== '/realtorIntroduction') {
				this.$router.push({ path: '/realtorIntroduction' });
			}
		},
		navigateToBrokerageIntroduction() {
			if (this.$route.fullPath !== '/brokerageIntroduction') {
				this.$router.push({ path: '/brokerageIntroduction' });
			}
		},
		navigateToCityProfileIntroduction() {
			if (this.$route.fullPath !== '/cityProfileIntroduction') {
				this.$router.push({ path: '/cityProfileIntroduction' });
			}
		},
		handleScroll() {
			this.navOffset = window.scrollY;
		},
		trigger() {
			this.triggered = true;
		},
		logout() {
			this.$store.dispatch("user/logout")
			this.$store.commit('api/acl/setSelfGroups', []);
			this.$store.commit('api/acl/setSelfPermissions', []);
			this.$store.commit('api/cart/setCart', { id: null, items: [] });
			this.$store.commit('api/rule/setHasNewMatched', 0);
			this.$store.commit('api/bookmarks/setTotalElements', null);
			this.$store.commit('api/bookmarks/setBookmarks', []);
			this.$store.commit('api/inbox/setUnreadCount', null);
			this.$store.commit('api/inbox/setIgnored', []);
			this.$store.commit('api/inbox/setUnread', {});
			this.triggered = false;
			localStorage.removeItem('cityToken');
		},
		toggleScrollLock: function (locked) {
			if (locked)
				document.documentElement.classList.add('overflow-y-hidden');
			else
				document.documentElement.classList.remove('overflow-y-hidden')
		},
		openPictureSelectDialog() {
			this.$refs.fileSelect.$refs.input.click();
		},
		uploadSuccess(file) {
			if (file) {
				this.profilePictureUploading = true;

				// Copy the user object and modify it
				let userObject = Object.assign({}, this.user);
				userObject.profilePicture = file;

				// Send the request, update the current user object.
				this.$axios
					.put("/api/v1/user/", userObject)
					.then(() => {
						this.$toasted.success(
							"Updated user profile picture successfully."
						);
						this.$store.dispatch("user/getUser");
					})
					.catch((e) => {
						this.$toasted.error(
							"Could not update user profile picture. " + e
						);
					})
					.finally(() => {
						this.profilePictureUploading = false;
					});

			} else this.$toasted.error("Invalid file selected.");
		},
		toShopSection(section, shop) {
			if (this.$route.path !== `/dashboard/marketplace/shops/shop/${shop.id}/${section}`) {
				this.$router.push({
					path: `/dashboard/marketplace/shops/shop/${shop.id}/${section}`,
					query: {
						shopName: shop.name
					}
				})
			}
		},
		openCopper(title, type) {
			this.cropper.title = title;
			this.cropper.type = type;
			this.cropperDialog = true;
		},
		closeCopper() {
			this.cropper.title = null;
			this.cropper.type = null;
			this.cropperDialog = false;
		},
		openImageDialog(type) {
			this.openCopper('Upload BREB Photo', 'photo');
		},
	},
	watch: {
		'active': function (v) {
			this.toggleScrollLock(v && this.mobile)
		},
		'mobile': function (v) {
			this.toggleScrollLock(v && this.active)
		}
	},
	mounted: function () {
		this.toggleScrollLock(this.active && this.mobile);
	}
}
</script>

<style lang="scss" scoped>
.welcome-background {
	background-color: var(--v-accent-lighten1);
	width: 100%;
	height: 60px;
	display: flex;
}
</style>
<style scoped>
/*
	>>> .v-list-item.v-list-item--active {
		color: yellow!important;
	}
	**/

>>>.v-list-group--disabled {
	background-color: darkgrey;
}

.list-subitem {
	background-color: #0b4073;
	padding-left: 32px !important;
}

.list-item-white>>>.v-list-item {
	border-color: white;
	border-style: solid;
	border-top-width: 0;
	border-bottom-width: 1px;
	border-left-width: 0;
	border-right-width: 0;
}

.list-item-white>>>.v-list-item:hover {
	background-color: #376386;
}

.row-fix>>>.v-list-group__header {
	flex-direction: row !important;
}

.list-item-white-bordered {}

.nav-clamped {
	max-width: 300px;
}

.nav-clamped.mobile {
	height: 100vh;
}

.v-list-group__header .v-list-group__header__prepend-icon i {
	transform: rotate(0deg) !important;
	transition: none !important;
}

.shop-group .v-list-group__items .v-list-item {
	padding-left: 48px !important;
}
</style>