<template>
	<div>
		<v-app-bar style="z-index: 10; " class="justify-center align-center no-padded-toolbar" app absolute clipped-left
			color="#072d52" elevate-on-scroll elevation="3" extension-height="39" extension-top="50" height="105px"
			max-height="137px">
			<div class="advertisement">
				<span>...Shop the BEST
					<span style="color: yellow">M</span>erchants,
					<span style="color: yellow">L</span>istings,
					<span style="color: yellow">$</span>avings
					and more...
				</span>
			</div>
			<v-row style="margin-top: 15px; padding: 0px 10px;">
				<v-toolbar-title class="text-center" prominent>
					<v-row class="align-center no-gutters">
						<span :style="$vuetify.breakpoint.mdAndDown ? 'max-width: 130px;' : 'max-width: 180px;'">
							<router-link to="/">
								<breb-async-image :external-source="BrebLogo"
									:max-height="$vuetify.breakpoint.mdAndDown ? 60 : 70"
									:max-width="$vuetify.breakpoint.mdAndDown ? 130 : 300" />
							</router-link>
						</span>
						<span v-show="$vuetify.breakpoint.smAndUp" class="text-left ml-2"
							:style="{ 'font-size': mobile ? '20px' : '22px' }">
							<a href="/" style="color: white; font-weight: 600;">
								<!-- BEST REAL ESTATE BUY -->
								<span style="color: yellow">B</span>EST
								<span style="color: yellow">R</span>EAL
								<span style="color: yellow">E</span>STATE
								<span style="color: yellow">B</span>UY
								<!-- <span style="color: yellow">POST. SEARCH. SHOP.</span> -->
							</a>
						</span>
					</v-row>
				</v-toolbar-title>

				<v-spacer />

				<v-toolbar-items v-if="$vuetify.breakpoint.mdAndUp">
					<v-btn class="menu-button" depressed link text tile to="/">
						<span class="hidden-md-and-down menu-font">Home</span>
						<v-icon class="menu-font-icon" size="24">home</v-icon>
					</v-btn>

					<v-btn v-if="!$vuetify.breakpoint.mobile" class="menu-button" depressed link text tile to="/about">
						<span class="hidden-md-and-down menu-font">About</span>
						<v-icon class="menu-font-icon" size="24">info</v-icon>
					</v-btn>

					<v-btn v-if="loggedIn" class="menu-button" depressed link text tile to="/dashboard/profile">
						<span class="hidden-md-and-down menu-font">Account</span>
						<v-icon class="menu-font-icon" size="24">account_circle</v-icon>
					</v-btn>
					<v-btn v-if="loggedIn" class="menu-button" depressed link style="position: relative" text tile
						to="/dashboard/bookmarks">
						<v-badge :value="bookmarksTotalElements" color="green" class="black--text">
							<template v-slot:badge>
								{{ bookmarksTotalElements > 9 ? '9+' : bookmarksTotalElements }}
							</template>
							<span class="hidden-md-and-down menu-font">Favourites</span>
							<v-icon class="menu-font-icon" size="24">mdi-cards-heart</v-icon>
						</v-badge>
					</v-btn>
					<v-btn class="menu-button" depressed link style="position: relative" text tile
						@click="clickMessages">
						<v-badge :value="notificationsUnread" color="red" class="black--text">
							<template v-slot:badge>
								{{ notificationsUnread > 9 ? '9+' : notificationsUnread }}
							</template>
							<span class="hidden-md-and-down menu-font">Messages</span>
							<v-icon class="menu-font-icon" size="24">mdi-message-badge</v-icon>
						</v-badge>
					</v-btn>
					<v-btn v-if="loggedIn" class="menu-button" depressed link style="position: relative" text tile
						to="/dashboard/alerts">
						<v-badge :value="matchedNumber" color="red" class="black--text">
							<template v-slot:badge>
								{{ matchedNumber > 9 ? '9+' : matchedNumber }}
							</template>
							<span class="hidden-md-and-down menu-font">Alerts</span>
							<v-icon class="menu-font-icon" size="24">
								{{ matchedNumber ? 'mdi-bell-ring' : 'mdi-bell' }}
							</v-icon>
						</v-badge>
					</v-btn>
					<v-btn class="menu-button" depressed link text tile @click="clickShoppingCart">
						<v-badge :value="itemsNumber" color="green" :content="itemsNumber" class="black--text">
							<span class="hidden-md-and-down menu-font">Cart</span>
							<v-icon class="menu-font-icon" size="24">shopping_cart</v-icon>
						</v-badge>
					</v-btn>
					<v-btn class="hidden-lg-and-up menu-button" depressed link text tile
						@click="activateNavigationMenu">
						<v-icon class="menu-font-icon" size="24">mdi-menu</v-icon>
					</v-btn>
					<v-btn v-if="loggedIn" class="menu-button menu-font" depressed link text tile @click="logout">
						<span>Sign Out</span>
						<v-icon class="menu-font-icon">mdi-logout</v-icon>
					</v-btn>

					<v-btn v-if="!loggedIn" class="menu-button menu-font-icon" depressed link text tile
						to="/dashboard/login">
						<span class="hidden-md-and-down menu-font">Sign In</span>
						<v-icon class="menu-font-icon" size="24">account_circle</v-icon>
					</v-btn>
				</v-toolbar-items>
				<v-toolbar-items v-else>
					<v-app-bar-nav-icon @click="clickSignInAccount"
						:style="!loggedIn ? 'width: 102px;' : 'width: 35px;'">
						<span v-if="!loggedIn" style="color:white; padding-right: 5px; font-size: 13px;">Sign In</span>
						<v-icon color="yellow">account_circle</v-icon>
					</v-app-bar-nav-icon>
					<v-app-bar-nav-icon @click="clickMessages" style="width: 35px">
						<v-badge :value="notificationsUnread" color="red" class="black--text">
							<template v-slot:badge>
								{{ notificationsUnread > 9 ? '9+' : notificationsUnread }}
							</template>
							<v-icon class="menu-font-icon" size="24">mdi-message-badge</v-icon>
						</v-badge>
					</v-app-bar-nav-icon>
					<v-app-bar-nav-icon @click="clickShoppingCart" style="width: 35px">
						<v-badge :value="itemsNumber" color="green" :content="itemsNumber" class="black--text">
							<v-icon color="yellow">shopping_cart</v-icon>
						</v-badge>
					</v-app-bar-nav-icon>
					<v-app-bar-nav-icon @click="activateNavigationMenu" style="width: 35px">
						<v-icon color="yellow">mdi-menu</v-icon>
					</v-app-bar-nav-icon>
				</v-toolbar-items>
			</v-row>
			<template v-slot:extension>
				<v-toolbar-items style="width: 100%;">
					<v-spacer v-if="cellphone" />
					<v-btn class="text-caption text-sm-uppercase menu-font"
						:class="cellphone ? 'pl-1 pr-1' : 'pl-3 pr-3'" color="white" text @click="clickPostListing">
						Post it&nbsp;
						<span style="color: yellow">FREE</span>&nbsp;
					</v-btn>
					<v-spacer v-if="cellphone" />
					<v-btn class="text-caption text-sm-uppercase menu-font"
						:class="cellphone ? 'pl-1 pr-1' : 'pl-3 pr-3'" color="white" text
						@click="navigateToMerchantIntroduction">
						Advertise Business
					</v-btn>
					<v-spacer v-if="cellphone" />
					<v-btn class="text-caption text-sm-uppercase menu-font"
						:class="cellphone ? 'pl-1 pr-1' : 'pl-3 pr-3'" color="white" text
						@click="navigateToMyBREBLocations">
						MyBREB Locations
					</v-btn>
					<v-btn class="hidden-sm-and-down text-caption text-sm-uppercase menu-font"
						:class="cellphone ? 'pl-1 pr-1' : 'pl-3 pr-3'" color="white" text
						@click="navigateToRealtorIntroduction">
						<span style="color: yellow">FREE</span>&nbsp;
						Realtor Profile
					</v-btn>
					<v-btn class="hidden-sm-and-down text-caption text-sm-uppercase menu-font"
						:class="cellphone ? 'pl-1 pr-1' : 'pl-3 pr-3'" color="white" text
						@click="navigateToBrokerageIntroduction">
						<span style="color: yellow">FREE</span>&nbsp;
						Brokerage Profile
					</v-btn>
					<v-btn class="hidden-sm-and-down text-caption text-sm-uppercase menu-font"
						:class="cellphone ? 'pl-1 pr-1' : 'pl-3 pr-3'" color="white" text
						@click="navigateToCityProfileIntroduction">
						<span style="color: yellow">MyCity</span>&nbsp;
						Profile & Directory
					</v-btn>
					<v-spacer />
					<!-- Buttons style themselves correctly. Its a hack here to use a button, but without it the v-spacer is inheriting the wrong width -->
					<v-btn to="/register" v-if="!loggedIn && !cellphone" class="text-caption" depressed
						style="background-color: transparent!important; color:white;">
						Get Started
					</v-btn>
				</v-toolbar-items>
			</template>
			<!-- <BrebMerchantPackage ref="merchantPackage" /> -->
			<ListingPermissionModal ref="postListingPermissionDialog" style="position: absolute" />

			<v-dialog v-if="brebLocationsVisible" :width="900" v-model="brebLocationsVisible" retain-focus
				transition="dialog-bottom-transition" :fullscreen="$vuetify.breakpoint.xs">
				<v-card>
					<v-btn class="close-btn" icon color="grey" @click="brebLocationsVisible = false;">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-card-text>
						<section class="pt-15 pb-10 reason text-center">
							<SubscriptionMapView @closeDialog="closeBrebLocations" />
						</section>
					</v-card-text>
					<v-card flat v-if="mobile"
						style="position: fixed; bottom: 0px; left: 0px; right: 0px; z-index: 3; background-color: lightgray;">
						<v-row class="ma-0" justify="center">
							<v-col cols="12">
								<BrebBtn :btnStyle="{ width: '100%', }" backgroundColor="yellow"
									@click="brebLocationsVisible = false;">
									Close Map Page
								</BrebBtn>
							</v-col>
						</v-row>
					</v-card>
				</v-card>
			</v-dialog>
		</v-app-bar>
	</div>
</template>

<script>
import format from "date-fns/format";
import BrebAsyncImage from "@/components/accessory/BrebImage";
import BrebBtn from "@/components/accessory/BrebBtn.vue";
import BrebLogo from "@/assets/breb.rect.logo.new-w200.png";
// import BrebMerchantPackage from "@/components/merchant/BrebMerchantPackage";
import ListingPermissionModal from "./user/ListingPermissionModal/ListingPermissionModal.vue";
import SubscriptionMapView from "@/views/root/SubscriptionMapView/SubscriptionMapView.vue";

const emitter = require("tiny-emitter/instance");
export default {
	name: "PersistentNavbar",
	components: {
		// BrebMerchantPackage,
		BrebAsyncImage,
		BrebBtn,
		ListingPermissionModal,
		SubscriptionMapView,
	},
	props: {},
	data: function () {
		return {
			drawer: false,
			dense: false,
			countries: [
				{ flag: "can", text: "Canada" },
				{ flag: "usa", text: "USA" },
				{ flag: "aus", text: "Australia" },
			],
			country: "can",
			currentTime: new Date(),
			interval: null,
			BrebLogo,
			emitter,
			brebLocationsVisible: false,
			itemsNumber: 0,
		};
	},
	methods: {
		activateNavigationMenu() {
			this.$emit("activateNav", null);
		},

		clickPostListing() {
			if (!this.loggedIn) {
				if (this.$route.fullPath !== '/dashboard/login') {
					this.$router.push({ name: "Login" });
				}
				return;
			}
			this.$router.push("/dashboard/listing/post");
		},
		navigateToMerchantIntroduction() {
			if (this.$route.fullPath !== '/merchantIntroduction') {
				this.$router.push({ path: '/merchantIntroduction' });
			}
		},
		navigateToMyBREBLocations() {
			if (this.$route.path !== '/subscription') {
				this.brebLocationsVisible = true;
			}
		},
		navigateToRealtorIntroduction() {
			if (this.$route.fullPath !== '/realtorIntroduction') {
				this.$router.push({ path: '/realtorIntroduction' });
			}
		},
		navigateToBrokerageIntroduction() {
			if (this.$route.fullPath !== '/brokerageIntroduction') {
				this.$router.push({ path: '/brokerageIntroduction' });
			}
		},
		navigateToCityProfileIntroduction() {
			if (this.$route.fullPath !== '/cityProfileIntroduction') {
				this.$router.push({ path: '/cityProfileIntroduction' });
			}
		},
		clickSignInAccount() {
			if (!this.loggedIn) {
				if (this.$route.fullPath !== '/dashboard/login') {
					this.$router.push({ path: '/dashboard/login' });
				}
				return;
			}
			this.$router.push({ path: '/dashboard/profile' });
		},
		clickMessages() {
			if (!this.loggedIn) {
				if (this.$route.fullPath !== '/dashboard/login') {
					this.$router.push({ path: '/dashboard/login' });
				}
				return;
			}
			if (this.$route.fullPath !== '/dashboard/notifications/list') {
				this.$router.push({ path: '/dashboard/notifications/list' });
			}
		},
		clickShoppingCart() {
			if (!this.loggedIn) {
				if (this.$route.fullPath !== '/dashboard/login') {
					this.$router.push({ path: '/dashboard/login' });
				}
				return;
			}
			if (this.$route.fullPath !== '/dashboard/marketplace/cart') {
				this.$router.push({ path: '/dashboard/marketplace/cart' });
			}
		},
		closeBrebLocations() {
			this.brebLocationsVisible = false;
		},
		logout() {
			this.$store.dispatch("user/logout")
			this.$store.commit('api/acl/setSelfGroups', []);
			this.$store.commit('api/acl/setSelfPermissions', []);
			this.$store.commit('api/cart/setCart', { id: null, items: [] });
			this.$store.commit('api/rule/setHasNewMatched', 0);
			this.$store.commit('api/bookmarks/setTotalElements', null);
			this.$store.commit('api/bookmarks/setBookmarks', []);
			this.$store.commit('api/inbox/setUnreadCount', null);
			this.$store.commit('api/inbox/setIgnored', []);
			this.$store.commit('api/inbox/setUnread', {});
			localStorage.removeItem('cityToken');
		},
	},
	computed: {
		mobile() {
			return this.$vuetify.breakpoint.mobile
		},
		cellphone() {
			return this.$vuetify.breakpoint.xs
		},
		now() {
			return format(this.currentTime, "PPpp");
		},
		topnav() {
			return this.$store.getters["settings/getTopNav"];
		},
		logoWidth: function () {
			return this.dense ? 40 : 120;
		},
		logoHeight: function () {
			return this.dense ? 40 : 90;
		},
		loggedIn() {
			return this.$store.getters["user/getLoggedIn"];
		},

		notificationsUnread() {
			return this.$store.getters["api/inbox/getUnreadCount"];
		},

		bookmarksTotalElements() {
			return this.$store.getters["api/bookmarks/getTotalElements"];
		},

		isPostingAllowed() {
			return this.$store.getters["user/isPostingAllowed"];
		},
		cart() {
			return this.$store.getters['api/cart/getCart'];
		},
		matchedNumber() {
			return this.$store.getters['api/rule/getHasNewMatched'];
		},
	},
	watch: {
		'cart': {
			deep: true,
			handler(v) {
				this.itemsNumber = v?.items.length || 0
			}
		}
	},
	mounted: function () {
		this.interval = setInterval(() => {
			this.currentTime = new Date();
		}, 1000);
	},
	beforeDestroy() {
		if (this.interval) clearInterval(this.interval);
	},
	created() {
		// let self = this;
		// this.emitter.on("show-merchant-package", function () {
		// 	self.$refs.merchantPackage.show();
		// });
	},
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/styles.sass";
@import "../../node_modules/vuetify/src/styles/settings/variables";
@import "../globals";

.advertisement {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	color: white;
	font-size: 13px;
	background: #0b4073;
	height: 25px;
	text-align: center;
	padding: 2px 10px 0px 10px;
	z-index: 12;
}

.amount-wrapper {
	background-color: #e1e1e1;
	position: absolute;
	right: -15px;
	top: -5px;
	width: 23px;
	height: 23px;
	border-radius: 50%;
	padding-left: 3px;
	padding-right: 3px;
	z-index: 10;

	.amount {
		line-height: 24px !important;
		font-size: 10px;
		color: $dark-blue;
		font-weight: bold;
	}
}
</style>

<style lang="less" scoped>
.menu-color {
	color: rgb(255, 255, 255) !important;
}

.menu-font {
	.menu-color;
	font-size: 14px;
	text-transform: none !important;
	font-weight: 300;
	text-transform: uppercase !important;
}

.menu-font-disabled {
	font-size: 14px;
	text-transform: none !important;
	color: grey !important;
}

.menu-font-icon {
	color: #faed23 !important;
	padding-left: 5px;

	// color: rgb(181, 166, 71) !important;
}

.menu-button {
	padding: 0 5px !important;
}

.menu-button::before {
	.menu-color;
	margin-top: 10px;
	margin-bottom: 10px;
	border-radius: 5px;
}

.menu-button:hover {
	.menu-color;
}

header>div.v-toolbar__content {
	padding: 0 !important;
}

.green-button {
	color: yellow !important;
}
</style>

<!-- This has to be global style, since this component is technically rendered on every page -->
<style>
header>div.v-toolbar__content {
	padding-right: 8px !important;
	padding-left: 8px !important;
}

div.v-toolbar__extension {
	background-color: #0b4073;
}

.custom.v-text-field>.v-input__control>.v-input__slot:before {
	border-style: none;
}

.custom.v-text-field>.v-input__control>.v-input__slot:after {
	border-style: none;
}


.close-btn {
	position: absolute;
	left: auto;
	right: 15px;
	top: 10px;
}
</style>
