<template>
	<v-card flat>
		<v-sheet v-if="externalSource">
			<BrebImg :externalSource="externalSource" :styles="styles" :height="height" :zoom="zoom"
				:rounded="rounded" />
		</v-sheet>
		<v-sheet v-else-if="file.mime_type.startsWith('video/')">
			<BrebVideo :file="file" :height="height" :styles="styles" :controls="controls" :showPlay="showPlay"
				:autoplay="autoplay" :loop="loop" :zoom="zoom" :showLogo="showLogo" />
		</v-sheet>
		<v-sheet v-else>
			<BrebImg :file="file" :styles="styles" :height="height" :zoom="zoom" :rounded="rounded" />
		</v-sheet>
	</v-card>
</template>

<script>
import BrebImg from "@/components/accessory/BrebImg.vue";
import BrebVideo from "@/components/accessory/BrebVideo.vue";

export default {
	name: "BrebFile",
	components: {
		BrebImg,
		BrebVideo,
	},
	mixins: [],
	props: {
		file: {
			type: Object,
			// required: true,
		},
		externalSource: {
			type: String
		},
		maxHeight: {
			type: String,
		},
		height: {
			type: [Number, String],
		},
		ratio: {
			type: String,
			default: () => '',
		},
		zoom: {
			type: String,
			default: 'cover'
		},
		//Properties for image only
		rounded: {
			type: Boolean,
			default: false,
		},
		//Properties for video only
		controls: {
			type: Boolean,
			default: true,
		},
		showPlay: {
			type: Boolean,
			default: true
		},
		autoplay: {
			type: Boolean,
			default: false,
		},
		loop: {
			type: Boolean,
			default: false,
		},
		showLogo: {
			type: Boolean,
			default: true,
		},
	},
	data: function () {
		return {

		}
	},
	computed: {
		mobile() {
			return this.$vuetify.breakpoint.mobile
		},
		cellphone() {
			return this.$vuetify.breakpoint.xs
		},
		styles() {
			return {
				'max-height': this.maxHeight,
				'aspect-ratio': this.aspectRatio,
			}
		},
		aspectRatio() {
			if (this.ratio === 'portrait') {
				return 3 / 4;
			} else if (this.ratio === 'logo') {
				return 3 / 2;
			} else if (this.ratio === 'photo') {
				return 1 / 1;
			} else if (this.ratio === 'image') {
				return 4 / 3;
			} else if (this.ratio === 'homepageSlider') {
				return 2 / 1;
			} else {
				return 16 / 9;
			}
		},
	},
	watch: {

	},
	methods: {

	},
	created() {

	},
	beforeMount() {

	},
	mounted() {

	},
	beforeDestroy() {

	},

}
</script>

<style scoped></style>

<style lang="scss" scoped>
@import "~vuetify/src/styles/styles.sass";
@import 'node_modules/vuetify/src/styles/settings/variables';
@import "src/globals";
@media only screen and (max-width: map-get($grid-breakpoints, 'sm')) {}
</style>