import axios from "axios";
import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        selfPermissions: [],
        selfGroups: [],
    },
    actions: {
        async getGroupsForSelf(ctx) {
            return axios.get(`/api/v1/user/groups/`).then(result => {
                if (result.data) {
                    ctx.commit('setSelfPermissions', result.data);
                    if(result.data.length !== 0){
                        ctx.commit('setSelfGroups', result.data.map(element => element.name));
                    }else{
                        ctx.commit('setSelfGroups', []);
                    }
                }
                return result;
            });
        },
        async getGroups(ctx) {
            return axios.get(`/api/v1/groups/`);
        },
        async getUsersForGroup(ctx, { group, page, size }) {
            return axios.get(`/api/v1/groups/${group}/members`,{
                params: {
                    start: page,
                    size: size,
                }
            });
        },
        async getGroupsForUser(ctx, { userId }) {
            return axios.get(`/api/v1/user/${userId}/groups`);
        },
        async inviteUserForGroup(ctx, { group, request }) {
            return axios.post(`/api/v1/groups/${group}/invite`, request);
        },
        async acceptInvite(ctx, { inviteId }) {
            return axios.post(`/api/v1/groups/invite/${inviteId}`);
        },
        async modifyGroupSettings(ctx, { group, request }) {
            return axios.post(`/api/v1/groups/${group}/settings`, request);
        },
        async removeUserFromGroup(ctx, { group, user }) {
            return axios.delete(`/api/v1/groups/${group}/${user}`);
        },

        //shop, brokerage, city and listing permissions 
        async getPermissionForSelf(ctx, { type, id }) {
            if(type === 'shop'){
                return await axios.get(`/api/v1/marketplace/${type}/${id}/permissions/self`);
            } else {
                return await axios.get(`/api/v1/${type}/${id}/permissions/self`);
            }
        },
        async getPermissionForUser(ctx, { type, id }) {
            if(type === 'shop'){
                return await axios.get(`/api/v1/marketplace/${type}/${id}/permissions`);
            } else {
                return await axios.get(`/api/v1/${type}/${id}/permissions`);
            }
        },
        async setPermissionForUser(ctx, { type, id, request }) {
            if(type === 'shop'){
                return axios.post(`/api/v1/marketplace/${type}/${id}/permissions`, request);
            } else {
                return axios.post(`/api/v1/${type}/${id}/permissions`, request);
            }
        },
        async deletePermissionForUser(ctx, { type, id, userId }) {
            if(type === 'shop'){
                return axios.delete(`/api/v1/marketplace/${type}/${id}/permissions/${userId}`);
            } else {
                return axios.delete(`/api/v1/${type}/${id}/permissions/${userId}`);
            }
        },
    },
    getters: {
        getSelfPermissions(state) {
            return state.selfPermissions;
        },
        getSelfGroups(state) {
            return state.selfGroups;
        },
    },
    mutations: {
        setSelfPermissions(state, payload) {
            state.selfPermissions = payload;
        },
        setSelfGroups(state, payload) {
            state.selfGroups = payload;
        },
    }
};
