<template>
	<div class="m-image">
		<v-img ref="image" :src="src" v-bind="$attrs" :eager="eager" v-on="$listeners" @error="error = $event"
			:width="width" :height="height" @load="handleLoad" :class="classes"
			:style="{ ...styles, 'object-fit': zoom, }">
			<slot name="img-overlay" />
		</v-img>
	</div>
</template>

<script>
export default {
	name: "BrebImg",
	props: {
		file: {
			type: Object,
		},
		externalSource: {
			type: String
		},
		rounded: {
			type: Boolean,
			default: false,
		},
		forceFill: {
			type: Boolean,
			default: false,
		},
		eager: {
			type: Boolean,
			default: false,
		},
		height: {
			type: [String],
		},
		width: {
			type: [String],
			default: '100%'
		},
		styles: {
			type: Object,
			default: () => { },
		},
		zoom: {
			type: String,
			default: 'contain'
		},
	},
	data: function () {
		return {
			error: '',
			loaded: false,
		}
	},
	computed: {
		classes: function () {
			return {
				'force-fill': this.forceFill,
				'image-rounded': this.rounded
			}
		},
		src() {
			if (this.externalSource) {
				return this.externalSource;
			} else {
				return this.getFileDisplaySource(this.file.mime_type, this.file.path);
			}
		},
	},
	methods: {
		handleLoad: function (ev) {
			this.loaded = true;
			this.$emit('imageload', ev)
		},
		getFileDisplaySource(mimeType, path) {
			if (this.file.mime_type.startsWith('image/')) {
				if (path.startsWith('/img')) {
					return path;
				} else {
					return this.$helpers.getFileUrl(path);
				}
			} else if (mimeType === 'application/pdf') {
				return require('@/assets/icons/pdf-icon.jpg');
			} else if (mimeType.startsWith('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
				return require('@/assets/icons/word-icon.jpeg');
			} else if (mimeType.startsWith('application/msword')) {
				return require('@/assets/icons/word-icon.jpeg');
			} else if (mimeType.startsWith('text/')) {
				return require('@/assets/icons/text-icon.jpeg');
			} else {
				return require('@/assets/icons/file-icon.jpeg');
			}
		},
	}
}
</script>

<style scoped>
.image-rounded {
	border-radius: 5px;
}

.force-fill /deep/ .v-image__image {
	background-size: 100% 100% !important;
}

.m-image {
	background-color: #d3d3d3;
	cursor: pointer;
}
</style>