<template>
	<v-img :contain="contain" ref="image" :src="src" v-bind="$attrs" :eager="eager" v-on="$listeners"
		@error="needsFallback = true; error = $event" :height="height" :width="width" @load="handleLoad"
		:class="classes" :style="{ ...styles }">
		<slot name="img-overlay" />
	</v-img>
</template>

<script>
export default {
	name: "BrebAsyncImage",
	props: {
		id: {
			type: Object,
			default: () => { }
		},
		/**
		 * fq = Full quality (source)
		 * hq = High Quality (1080p)
		 * mq = Medium Quality (720p)
		 * thumbnail = Thumbnail (360p)
		 */
		quality: {
			type: String,
			required: false,
			default: () => 'fq'
		},
		rounded: {
			type: Boolean,
			default: () => false
		},
		forceFill: {
			type: Boolean,
			default: () => false
		},
		externalSource: {
			type: String
		},
		eager: {
			type: Boolean,
			default: () => false
		},
		contain: {
			type: Boolean,
			default: () => false
		},
		height: {},
		width: {},
		styles: {},
	},
	data: function () {
		return {
			needsFallback: false,
			error: '',
			loaded: false,
		}
	},
	computed: {
		src: function () {
			if (this.externalSource) {
				return this.externalSource;
			} else {
				return this.$helpers.getFileUrl(this.id.path);
			}
		},
		classes: function () {
			return {
				'force-fill': this.forceFill,
				'image-rounded': this.rounded
			}
		}
	},
	methods: {
		handleLoad: function (ev) {
			this.loaded = true;
			this.$emit('imageload', ev)
		}
	},
}
</script>

<style scoped>
.image-rounded {
	border-radius: 15px;
}

.force-fill /deep/ .v-image__image {
	background-size: 100% 100% !important;
}
</style>