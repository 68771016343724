import axios from "axios";

export default {
    namespaced: true,
    state: {
        cards: [],
        hasCards: false,
    },
    mutations: {
        setCards(state, payload) {
            state.cards = payload;
        },
        setHasCards(state, payload) {
            state.hasCards = payload;
        },
    },
    getters: {
        getCards: function (state) {
            return state.cards;
        },

        getHasCards: function (state) {
            return state.hasCards;
        },
    },
    actions: {
        async getCards(ctx) {
            return axios.get(`/api/v1/customer/cards`).then((response) => {
                ctx.commit("setCards", response.data);
                if (response.data.length > 0) {
                    ctx.commit("setHasCards", true);
                } else {
                    ctx.commit("setHasCards", false);
                }
                return response;
            });
        },

        async deletePaymentMethod(ctx, { paymentMethodId }) {
            return axios.delete(`/api/v1/customer/${paymentMethodId}`);
        },

        async setup() {
            return axios.post(`/api/v1/customer/setup`);
        },
    },
};
