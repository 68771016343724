<template>
	<v-dialog :width="900" v-model="visible" retain-focus transition="dialog-bottom-transition"
		:fullscreen="$vuetify.breakpoint.xs">
		<v-card>
			<v-btn class="close-btn" icon color="grey" @click="visible = false;">
				<v-icon>mdi-close</v-icon>
			</v-btn>

			<v-card-text class="pa-2">
				<section class="pt-15 reason text-center">
					<div class="row mb-6 reason-box ma-0">
						<v-col class="font-weight-bold mx-auto">
							<h2 class="reason-title text-center">
								Why Join & Become a Participating <br v-if="!mobile" />
								MyBREB Team Merchant and/or Local Sponsors
							</h2>
						</v-col>
						<v-col class="col-12 pa-0"></v-col>
						<v-col class="col-12 col-md-6 align-self-center px-4">
							<div class="row mt-4 align-center">
								<v-col class="col-12 col-sm-3">
									<img src="../../assets/about/different.png" alt="different">
									<div class="font-weight-bold reason-subtitle">We’re Different</div>
								</v-col>
								<v-col class="col-12 col-sm-9">
									<div class="reason-desc">
										<p>As a company we have the marketing and advertising experience needed to
											position and grow your brand, and help increase your company’s year end
											sales purchases.</p>
									</div>
								</v-col>
							</div>
						</v-col>
						<v-col class="col-12 col-md-6 align-self-center px-4">
							<div class="row mt-4 align-center">
								<v-col class="col-12 col-sm-3">
									<img src="../../assets/about/platform.png" alt="platform">
									<div class="font-weight-bold reason-subtitle">We Give Your Brand a Platform</div>
								</v-col>
								<v-col class="col-12 col-sm-9">
									<div class="reason-desc">
										<p>
											With participating MyBREB Team Merchants and a data-driven marketing
											approach, we make it easy and exciting for customers to explore your brand.
										</p>
									</div>
								</v-col>
							</div>
						</v-col>
						<v-col class="col-12 col-md-6 align-self-center px-4">
							<div class="row mt-4 align-center">
								<v-col class="col-12 col-sm-3">
									<img src="../../assets/about/evolving.png" alt="evolving">
									<div class="font-weight-bold reason-subtitle">We’re Constantly Evolving+</div>
								</v-col>
								<v-col class="col-12 col-sm-9">
									<div class="reason-desc">
										<p>As a participating MyBREB Team merchant, we create a custom Adspace for you
											to share your company’s story, spread your brand awareness, and
											increase your year end revenues.</p>
									</div>
								</v-col>
							</div>
						</v-col>
						<v-col class="col-12 col-md-6 align-self-center px-4">
							<div class="row mt-4 align-center">
								<v-col class="col-12 col-sm-3">
									<img src="../../assets/about/curators.png" alt="curators">
									<div class="font-weight-bold reason-subtitle">We’re Dedicated Curators</div>
								</v-col>
								<v-col class="col-12 col-sm-9">
									<div class="reason-desc">
										<p>We offer more real estate related products and services but we make it easy
											for shoppers to engage with your brand by using customer behavior analytics
											to help guide them through their shopping experience.</p>
									</div>
								</v-col>
							</div>
						</v-col>
					</div>
				</section>
				<section class="row justify-center py-6 advantages ma-0">
					<v-col class="col-12 advantages-title">
						What do we give you?
					</v-col>
					<v-col class="col-12 col-sm-6 col-md-4 my-4">
						<div class="advantages-subtitle mb-3">FREE</div>
						<div class="font-weight-bold">
							Search Engine Optimization (SEO) & Unlimited Social Media Exposure.
						</div>
					</v-col>
					<v-col class="col-12 col-sm-6 col-md-4 my-4">
						<div class="advantages-subtitle mb-3">FREE</div>
						<div class="font-weight-bold">
							Unlimited Leads, Quotes and Appointment Bookings.
						</div>
					</v-col>
					<v-col class="col-12 col-sm-6 col-md-4 my-4">
						<div class="advantages-subtitle mb-3">FREE</div>
						<div class="font-weight-bold">
							Unlimited Access to the MyBREB advertising network.
						</div>
					</v-col>
					<v-col class="col-12 col-sm-6 col-md-4 my-4">
						<div class="advantages-subtitle mb-3">FREE</div>
						<div class="font-weight-bold">
							Unlimited Ability to post Real Estate Listings, Events, Jobs, Classifieds, Specials and
							Coupons.
						</div>
					</v-col>
					<v-col class="col-12 col-sm-6 col-md-4 my-4">
						<div class="advantages-subtitle mb-3">Bonus</div>
						<div class="font-weight-bold">
							FREE Company Directories to allow our users to easily contact your employees.
						</div>
					</v-col>
					<v-col class="col-12 col-sm-6 col-md-4 my-4">
						<div class="advantages-subtitle mb-3">Bonus</div>
						<div class="font-weight-bold">
							Unlimited Access to Post & List Sale Items on BREB’s eCommerce Marketplace Platform.
						</div>
					</v-col>
				</section>
				<section class="col pa-0" style="height: 100%;">
					<v-row class="ma-0 pt-5" justify="center">
						<v-col cols="auto" class="pa-2 page-title" style="border: 1px solid black; color: #d8433a;">
							Get Started Today
						</v-col>
					</v-row>
					<v-row class="ma-0 pa-1 page-subtitle" justify="center">
						Five (5) Easy Steps
					</v-row>
					<v-row class="ma-0 pb-10" justify="center" align="center">
						<v-col :cols="mobile ? 12 : 8" class="pa-0">
							<v-row class="ma-0 pt-4" justify="center" align="center">
								<v-col cols="auto" class="pa-0" align-self="center">
									<v-icon color="#0b4073" large>
										mdi-numeric-1-circle
									</v-icon>
								</v-col>
								<v-col class="pa-0 pl-2">
									<v-btn class="view-btn" color="accent" elevation="0" @click="sendToRegistration">
										Register&nbsp;<span style="color: yellow;">Now</span>
									</v-btn>
								</v-col>
							</v-row>
							<v-row class="ma-0 pt-4" justify="center" align="center">
								<v-col cols="auto" class="pa-0" align-self="center">
									<v-icon color="#0b4073" large>
										mdi-numeric-2-circle
									</v-icon>
								</v-col>
								<v-col class="pa-0 pl-2">
									<v-btn class="view-btn" color="success" elevation="0" @click="sendToPostListing">
										Create Merchant Listing
									</v-btn>
								</v-col>
							</v-row>
							<v-row class="ma-0 pt-4" justify="center" align="center">
								<v-col cols="auto" class="pa-0" align-self="center">
									<v-icon color="#0b4073" large>
										mdi-numeric-3-circle
									</v-icon>
								</v-col>
								<v-col class="pa-0 pl-2">
									<v-btn class="view-btn" color="orange" style="color: white;" elevation="0"
										@click="sendToMarketingPackage">
										Select Subscription Packages
									</v-btn>
								</v-col>
							</v-row>
							<v-row class="ma-0 pt-4" justify="center" align="center">
								<v-col cols="auto" class="pa-0" align-self="center">
									<v-icon color="#0b4073" large>
										mdi-numeric-4-circle
									</v-icon>
								</v-col>
								<v-col class="pa-0 pl-2">
									<v-btn class="view-btn" color="#ffeb3b" elevation="0" @click="sendToSubscriptions">
										Select BREB Board Locations
									</v-btn>
								</v-col>
							</v-row>
							<v-row class="ma-0 pt-4" justify="center" align="center">
								<v-col cols="auto" class="pa-0" align-self="center">
									<v-icon color="#0b4073" large>
										mdi-numeric-5-circle
									</v-icon>
								</v-col>
								<v-col class="pa-0 pl-2">
									<v-btn class="view-btn" color="#5d8cb9" style="color: white;" elevation="0"
										@click="sendToSubscription">
										Buy Locations/Checkout
									</v-btn>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</section>
			</v-card-text>
		</v-card>
		<BrebDialog title="Notice" content="You are not a merchant yet. Please register as a merchant first."
			buttonContent="Register Merchant" :buttonFunction="clickRegisterMerchant"
			:isDialogShown="isLoginDialogShown" secondButtonContent="Cancel" :secondButtonFunction="clickCancel"
			:width="600" />
	</v-dialog>
</template>

<script>
import BrebDialog from '@/components/accessory/BrebDialog.vue';

export default {
	name: "BrebMerchantPackage",
	components: {
		BrebDialog,
	},
	data: function () {
		return {
			visible: false,
			nojunk: true,
			benefits: [
				"Merchants",
				"Local Sponsors",
				"Realtors",
				"Private Owners",
				"Rentals / Apartments",
				"Vacation Home Owners",
				"Retirement Homes",
				"New Build Developers",
				"Investment Managers",
				"Many Others!"
			],
			isLoginDialogShown: false,
		}
	},
	computed: {
		cellphone() {
			return this.$vuetify.breakpoint.xs
		},
		mobile() {
			return this.$vuetify.breakpoint.mobile
		},
		isLoggedIn() {
			return this.$store.getters['user/getLoggedIn'];
		},
		hasMerchantCreate() {
			return this.$helpers.hasMerchantCreate(this.$store);
		},
	},
	methods: {
		sendToRegistration() {
			if (this.isLoggedIn) {
				if (this.$route.fullPath !== '/dashboard/profile?tab=merchant') {
					this.$router.push({ path: '/dashboard/profile', query: { tab: 'merchant' } })
				}
			} else {
				if (this.$route.fullPath !== '/registerMerchant') {
					this.$router.push({ path: '/registerMerchant' })
				}
			}
			this.visible = false;
		},

		sendToSubscriptions() {
			if (this.$route.fullPath !== '/subscription-map') {
				this.$router.push({ path: '/subscription-map' });
			}
			this.visible = false;
		},

		sendToMarketingPackage() {
			if (this.$route.fullPath !== '/subscriptions') {
				this.$router.push('/subscriptions');
			}
			this.visible = false;
		},


		sendToSubscription() {
			if (!this.hasMerchantCreate) {
				this.isLoginDialogShown = true;
				return;
			}

			if (this.$route.fullPath !== '/subscription') {
				this.$router.push({ path: '/subscription' });
			}
			this.visible = false;
		},

		sendToPostListing() {
			if (!this.hasMerchantCreate) {
				this.isLoginDialogShown = true;
				return;
			}

			this.$router.push({
				path: '/dashboard/listing/post',
				query: {
					postMerchant: true
				}
			})
			this.visible = false;
		},
		clickRegisterMerchant() {
			this.$router.push({ path: '/dashboard/profile', query: { tab: 'merchant' } })
			this.isLoginDialogShown = false;
			this.visible = false;
		},
		clickCancel() {
			this.isLoginDialogShown = false;
		},
		show() {
			this.visible = true;
		}
	}
}
</script>

<style scoped>
.close-btn {
	position: absolute;
	left: auto;
	right: 15px;
	top: 10px;
}

.v-card__title {
	word-break: break-word !important;
}
</style>

<style scoped lang="scss">
@import './node_modules/vuetify/src/styles/settings/variables';
@import "src/globals";

.title {
	font-size: 30px;
	font-weight: bold;
}

.view-btn {
	width: 100%;
	padding-left: 8px;
	padding-right: 8px;
}

.reason-box {
	border: 1px solid $main-color;
	border-radius: 5px;
}

.reason-title {
	font-size: 24px;
	font-weight: bold;
	color: $main-color;
	line-height: 28px;
}

.reason-subtitle {
	color: black;
}

.reason-desc {
	text-align: start;
	color: black;
	font-weight: bold;
}

.advantages-title {
	font-size: 24px;
	font-weight: bold;
	color: $main-color
}

.advantages-subtitle {
	font-size: 20px;
	font-weight: bold;
	text-decoration: underline;
	color: black;
}

.advantages {
	background: url("../../assets/about/advantages-background.jpg") center center/cover no-repeat;
	color: black;
	text-align: center;
}

// #072d52
// #666666
@media only screen and (max-width: map-get($grid-breakpoints, 'sm')) {

	.reason-title {
		font-size: 20px;
	}

	.advantages-title {
		font-size: 20px;
		font-weight: bold;
	}

	.advantages-subtitle {
		font-size: 18px;
	}
}
</style>
