<template>
    <div>
        <v-overlay v-if="loading" opacity="0.6" absolute>
            <v-progress-circular indeterminate color="secondary"></v-progress-circular>
        </v-overlay>
        <v-card flat class="mx-auto">
            <v-btn class="close-btn" @click="closeCopper" outlined icon>
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-card-title class="page-title pa-2">
                {{ title }}
            </v-card-title>
            <v-card-text v-if="img" class="pa-2">
                <Cropper ref="cropper" :src="img" :stencil-props="{ handlers: {}, movable: false, resizable: false, }"
                    :stencil-size="{ width: this.dimensions.width, height: this.dimensions.height }"
                    image-restriction="stencil" @change="change" :style="style" :default-size="defaultSize"
                    :min-height="limitations.minHeight" :min-width="limitations.minWidth"
                    :canvas="{ maxWidth: limitations.maxWidth, }" />
            </v-card-text>
            <v-card-text v-else class="pa-2">
                <div style="width: 100%; margin-top: 5px; margin-bottom: 5px;" @click="openPictureSelectDialog">
                    <v-overlay absolute v-if="profilePictureUploading">
                        Uploading Profile Picture
                        <v-progress-circular indeterminate color="amber"></v-progress-circular>
                    </v-overlay>
                    <div
                        style="display: flex; justify-content: center; align-items: center; height: 300px; border: solid 1px black; border-radius: 5px; font-size: 20px; background-color: #F5F5F5; cursor: pointer;">
                        {{ allowVideo ? 'Choose Image Or Video' : 'Choose Image' }}
                    </div>
                </div>
            </v-card-text>
            <v-row class="ma-0 page-body" :class="mobile ? 'pa-2' : ''" style="color: orange;">
                <v-alert outlined color="orange" class="pa-2 mb-2">
                    Please drag and move the image to crop it.
                </v-alert>
            </v-row>
            <v-card-actions :class="mobile ? '' : 'pa-0'">
                <v-row class="ma-0" :justify="mobile ? 'space-between' : 'center'">
                    <BrebBtn icon @click="zoom(1.2)" btnClass="mx-2">
                        <v-icon large>mdi-magnify-plus-outline</v-icon>
                    </BrebBtn>
                    <BrebBtn icon @click="zoom(0.8333)" btnClass="mx-2">
                        <v-icon large>mdi-magnify-minus-outline</v-icon>
                    </BrebBtn>
                    <!-- <BrebBtn icon @click="flip(true, false)" btnClass="mx-2">
                        <v-icon large>mdi-flip-horizontal</v-icon>
                    </BrebBtn>
                    <BrebBtn icon @click="flip(false, true)" btnClass="mx-2">
                        <v-icon large>mdi-flip-vertical</v-icon>
                    </BrebBtn> -->
                    <!-- <BrebBtn @click="zoom(0.6667)">zoom out</BrebBtn> -->
                    <BrebBtn icon @click="rotate(-90)" btnClass="mx-2">
                        <v-icon large>mdi-file-rotate-left-outline</v-icon>
                    </BrebBtn>
                    <BrebBtn icon @click="rotate(90)" btnClass="mx-2">
                        <v-icon large>mdi-file-rotate-right-outline</v-icon>
                    </BrebBtn>
                </v-row>
            </v-card-actions>
            <v-card-actions :class="mobile ? 'pt-6' : 'pa-0'">
                <v-row class="ma-0" justify="center" align="center">
                    <BrebBtn backgroundColor="green" @click="uploadFile" btnClass="mx-2 my-2"
                        :btnStyle="{ width: '220px', }">
                        Upload
                    </BrebBtn>
                    <label v-if="!lisitngImage" class="custom-file-input mx-2" style="width: 220px;"
                        @click="openPictureSelectDialog">
                        {{ allowVideo ? 'Choose Image Or Video' : img ? 'Choose Other Image' : 'Choose Image' }}
                    </label>
                    <!-- <input id="file-input" type="file" accept="image/*" capture="environment" @change="onFileChange" /> -->
                    <v-file-input v-if="!lisitngImage" :accept="accept" id="file-input" style="display: none;"
                        ref="fileSelect" @change="onFileChange" />
                </v-row>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import BrebBtn from "@/components/accessory/BrebBtn.vue"
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

export default {
    name: "BrebCropper",
    components: {
        BrebBtn,
        Cropper,
    },
    mixins: [],
    props: {
        title: {
            type: String,
            default: "Upload an Image"
        },
        uploadType: {
            type: String,
            default: '',
        },
        stencilCoordinates: {
            type: Object,
        },
        lisitngImage: {
            default: null,
        },
        allowVideo: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {
            loading: false,
            img: null,
            newFileName: null,
            selectedFile: null,
            limitations: {
                minWidth: 100,
                minHeight: 120,
                maxWidth: 1280,
                // maxHeight: 1536,
            },
            originalFileName: null,
            profilePictureUploading: false,
        }
    },
    computed: {
        mobile() {
            return this.$vuetify.breakpoint.mobile
        },
        cellphone() {
            return this.$vuetify.breakpoint.xs
        },
        style() {
            return {
                width: `100%`,
                height: `300px`,
                transform: `translate(${0}px, ${0}px)`,
            };
        },
        ratio() {
            if (this.uploadType === 'portrait') {
                return {
                    width: 3,
                    height: 4,
                }
            } else if (this.uploadType === 'logo') {
                return {
                    width: 3,
                    height: 2,
                }
            } else if (this.uploadType === 'photo') {
                return {
                    width: 1,
                    height: 1,
                }
            } else if (this.uploadType === 'image') {
                return {
                    width: 4,
                    height: 3,
                }
            } else if (this.uploadType === 'homepageSlider') {
                return {
                    width: 2,
                    height: 1,
                }
            } else {
                return {
                    width: 16,
                    height: 9,
                }
            }
        },
        dimensions() {
            if (this.uploadType === 'portrait') {
                return {
                    width: this.ratio.width * (this.mobile ? 60 : 60),
                    height: this.ratio.height * (this.mobile ? 60 : 60),
                }
            } else if (this.uploadType === 'logo') {
                return {
                    width: this.ratio.width * (this.mobile ? 80 : 120),
                    height: this.ratio.height * (this.mobile ? 80 : 120),
                }
            } else if (this.uploadType === 'photo') {
                return {
                    width: this.ratio.width * (this.mobile ? 200 : 200),
                    height: this.ratio.height * (this.mobile ? 200 : 200),
                }
            } else if (this.uploadType === 'image') {
                return {
                    width: this.ratio.width * (this.mobile ? 60 : 80),
                    height: this.ratio.height * (this.mobile ? 60 : 80),
                }
            } else if (this.uploadType === 'homepageSlider') {
                return {
                    width: this.ratio.width * (this.mobile ? 150 : 200),
                    height: this.ratio.height * (this.mobile ? 150 : 200),
                }
            } else {
                return {
                    width: this.ratio.width * (this.mobile ? 20 : 25),
                    height: this.ratio.height * (this.mobile ? 20 : 25),
                }
            }
        },
        accept() {
            return this.allowVideo ? 'image/*,video/*' : 'image/*';
        }
    },
    watch: {

    },
    methods: {
        change({ coordinates, canvas }) {
            // console.log("++++++", coordinates, canvas)
        },
        defaultSize({ imageSize, visibleArea }) {
            return {
                width: (visibleArea || imageSize).width - 200,
                height: (visibleArea || imageSize).height - 200,
            };
        },
        zoom(factor) {
            this.$refs.cropper.zoom(factor);
        },
        rotate(angle) {
            this.$refs.cropper.rotate(angle);
        },
        flip(x, y) {
            const { image } = this.$refs.cropper.getResult();
            if (image.transforms.rotate % 180 !== 0) {
                this.$refs.cropper.flip(!x, !y);
            } else {
                this.$refs.cropper.flip(x, y);
            }
        },
        closeCopper() {
            this.$emit('closeCopper')
        },
        onFileChange(file) {
            this.profilePictureUploading = true;
            if (file) {
                if (!file.type.startsWith('image/') && !file.type.startsWith('video/')) {
                    this.$toasted.show('Please choose a valid file.')
                    this.profilePictureUploading = false;
                    return;
                }
                if (file.type.startsWith('image/')) {
                    // handling image file change
                    // Maximun file size 10MB
                    const maxSize = 10 * 1024 * 1024;
                    if (file.size > maxSize) {
                        this.$toasted.show('The maximun of the image size is 10MB.')
                        this.profilePictureUploading = false;
                        return;
                    }

                    this.originalFileName = file.name;
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        this.img = e.target.result;
                    };

                    reader.readAsDataURL(file instanceof File ? file : file.blob);
                } else {
                    // handling video file change
                    const maxSize = 1000 * 1024 * 1024;
                    if (file.size > maxSize) {
                        this.$toasted.show('The maximun of the video size is 1GB.')
                        return;
                    }

                    this.newFileName = this.$helpers.addTimestampToFileName(file.name);
                    this.selectedFile = file;
                    this.uploadFile();
                }
            } else {
                this.originalFileName = null;
                this.img = null;
                this.newFileName = null;
                this.selectedFile = null;
            }
            this.profilePictureUploading = false;
        },
        uploadFile() {
            if (this.img) {
                this.uploadImage()
            } else if (this.selectedFile) {
                this.uploadVideo()
            }
        },
        uploadImage() {
            if (this.$refs.cropper) {
                const { canvas } = this.$refs.cropper.getResult();
                if (canvas) {
                    canvas.toBlob((blob) => {
                        const fileWithOriginalName = new File([blob], this.originalFileName, { type: blob.type });
                        this.loading = true;
                        let newFileName = this.$helpers.addTimestampToFileName(this.originalFileName)
                        this.$store.dispatch("api/file/uploadFile", {
                            file: fileWithOriginalName,
                            name: newFileName,
                        }).then((response) => {
                            if (response.status === 200) {
                                let uploadFile = {
                                    lastModifiedDate: fileWithOriginalName.lastModified,
                                    size: fileWithOriginalName.size,
                                    mime_type: fileWithOriginalName.type,
                                    id: response.data.id,
                                    path: response.data.path,
                                    url: this.$helpers.getFileUrl(response.data.path),
                                    name: newFileName,
                                }
                                this.$emit('uploadSuccess', uploadFile, this.lisitngImage);
                                // this.$emit('addFileToDragZone', uploadFile, fileWithOriginalName);
                                this.$emit('closeCopper');
                                this.$toasted.success('Successfully uploaded the file');
                            } else {
                                this.$toasted.success('Fail to upload file, the file has exceeded the total maximum capacity');
                            }
                        }).catch(error => {
                            this.$toasted.error('Network error, fail to upload file, please try again later.');
                        }).finally(() => {
                            this.loading = false;
                        })
                    }, 'image/jpeg');
                } else {
                    this.$toasted.error('Can’t read this image. Please choose a valid image file.');
                }
            } else {
                this.$toasted.show('Please choose a valid image file.');
            }
        },
        uploadVideo() {
            if (this.selectedFile === null) {
                this.$toasted.show('Please select a file to upload');
                return
            }
            this.loading = true;
            this.$store.dispatch('api/file/uploadFile', {
                file: this.selectedFile,
                name: this.newFileName,
            }).then((res) => {
                if (res.status === 200) {
                    res.data.mime_type = this.selectedFile.type
                    this.$emit('uploadSuccess', res.data);
                    this.$emit('closeCopper')
                    this.$toasted.success('Successfully uploaded the file');
                } else {
                    this.$toasted.success('Fail to upload file, the file has exceeded the total maximum capacity');
                }
            }).catch((err) => {
                this.$toasted.success('Fail to upload video');
            }).finally(() => {
                // this.$refs.fileSelect.reset();
                this.selectedFile = null;
                this.newFileName = null;
                this.loading = false;
            });
        },
        openPictureSelectDialog() {
            this.originalFileName = null;
            this.img = null;
            this.newFileName = null;
            this.selectedFile = null;
            this.$refs.fileSelect.$refs.input.click();
        },
    },
    created() {

    },
    mounted() {
        if (!this.lisitngImage) {
            this.$refs.fileSelect.$refs.input.click();
        } else {
            if (this.lisitngImage instanceof File) {
                this.onFileChange(this.lisitngImage);
            } else {
                this.img = this.lisitngImage.url;
                this.originalFileName = this.lisitngImage.name;
            }
        }
    },

}
</script>

<style scoped>
.custom-file-input {
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    background-color: #0b4073;
    color: white;
    border: none;
    border-radius: 4px;
    text-align: center;
    height: 36px;
    font-size: 16px;
}

.custom-file-input:hover {
    background-color: #0b4073;
}

input[type="file"] {
    display: none;
}
</style>

<style lang="scss" scoped>
@import "~vuetify/src/styles/styles.sass";
@import 'node_modules/vuetify/src/styles/settings/variables';
@import "src/globals";

.close-btn {
    position: absolute;
    z-index: 1;
    right: 8px;
    top: 8px;
    color: #cccccc;
    background-color: #cccccc;
    border: solid 0px black;
}

@media only screen and (max-width: map-get($grid-breakpoints, 'sm')) {}
</style>