var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-btn',{class:_vm.fontSize + ' ' + _vm.btnClass,style:({
		'background-color': _vm.icon ? 'transparent' : '',
		'text-transform': _vm.textTransform,
		'font-weight': _vm.fontWeight,
		..._vm.btnColor,
		..._vm.btnStyle,
	}),attrs:{"text":_vm.text,"link":_vm.link,"icon":_vm.icon,"small":_vm.small,"x-small":_vm.xSmall,"large":_vm.large,"x-large":_vm.xLarge,"absolute":_vm.absolute,"left":_vm.left,"right":_vm.right,"bottom":_vm.bottom,"top":_vm.top,"rounded":_vm.rounded,"disabled":_vm.disabled,"outlined":_vm.outlined,"href":_vm.href,"plain":_vm.plain,"ripple":_vm.ripple,"elevation":_vm.elevation},on:{"click":_vm.handleClick}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }