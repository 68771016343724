<template>
	<v-btn :text="text" :link="link" :icon="icon" :small="small" :x-small="xSmall" :large="large" :x-large="xLarge"
		:absolute="absolute" :left="left" :right="right" :bottom="bottom" :top="top" :rounded="rounded"
		:disabled="disabled" :outlined="outlined" :href="href" :plain="plain" :ripple="ripple" :elevation="elevation"
		:style="{
			'background-color': icon ? 'transparent' : '',
			'text-transform': textTransform,
			'font-weight': fontWeight,
			...btnColor,
			...btnStyle,
		}" :class="fontSize + ' ' + btnClass" @click="handleClick">
		<slot></slot>
	</v-btn>
</template>

<script>

export default {
	name: "BrebBtn",
	props: {
		text: {
			type: Boolean,
			default: true
		},
		link: {
			type: Boolean,
			default: false
		},
		icon: {
			type: Boolean,
			default: false
		},
		color: {
			type: String,
			default: ""
		},
		backgroundColor: {
			type: String,
			default: "dark-blue"
		},
		textTransform: {
			type: String,
			default: "none"
		},
		fontWeight: {
			type: String,
			default: "normal"
		},
		disabled: {
			type: Boolean,
			default: false
		},
		outlined: {
			type: Boolean,
			default: false
		},
		small: {
			type: Boolean,
			default: false
		},
		xSmall: {
			type: Boolean,
			default: false
		},
		large: {
			type: Boolean,
			default: false
		},
		xLarge: {
			type: Boolean,
			default: false
		},
		absolute: {
			type: Boolean,
			default: false
		},
		left: {
			type: Boolean,
			default: false
		},
		right: {
			type: Boolean,
			default: false
		},
		bottom: {
			type: Boolean,
			default: false
		},
		top: {
			type: Boolean,
			default: false
		},
		rounded: {
			type: Boolean,
			default: false
		},
		plain: {
			type: Boolean,
			default: false
		},
		ripple: {
			type: Boolean,
			default: true
		},
		btnStyle: {
			type: Object,
			default: () => { }
		},
		btnClass: {
			type: String,
			default: ""
		},
		fontSize: {
			type: String,
			default: 'text-body-1'
		},
		href: {
			type: String,
			default: undefined
		},
		elevation: {
			type: [String, Number],
			default: 0
		}
	},
	data: function () {
		return {

		}
	},
	computed: {
		btnColor() {
			if (this.icon) {
				return {
					'background-color': 'transparent',
					'color': this.color ? this.color : '#0b4073',
				}
			}
			if (this.link) {
				return {
					'background-color': 'transparent',
					'color': this.color ? this.color : 'black',
					'text-decoration': 'underline',
					'padding': 0 + 'px',
					'min-width': 0 + 'px'
				}
			}
			if (this.backgroundColor === 'dark-blue') {
				return {
					'background-color': '#0b4073',
					'color': this.color ? this.color : 'white',
				}
			} else if (this.backgroundColor === 'green') {
				return {
					'background-color': '#4caf50',
					'color': this.color ? this.color : 'white',
				}
			} else if (this.backgroundColor === 'yellow') {
				return {
					'background-color': '#ffeb3b',
					'color': this.color ? this.color : 'black',
				}
			} else if (this.backgroundColor === 'red') {
				return {
					'background-color': '#d8433a',
					'color': this.color ? this.color : 'white',
				}
			} else if (this.backgroundColor === 'light-blue') {
				return {
					'background-color': '#5d8cb9',
					'color': this.color ? this.color : 'white',
				}
			} else if (this.backgroundColor === 'main-color') {
				return {
					'background-color': '#072d52',
					'color': this.color ? this.color : 'white',
				}
			} else {
				return {
					'background-color': '#072d52',
					'color': this.color ? this.color : 'white',
				}
			}
		},
	},
	methods: {
		handleClick(v) {
			this.$emit('click', v);
		},
	},
	mounted() {

	}
}
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables.scss';
@import "src/globals";
</style>