import axios from "axios";

export default {
	namespaced: true,
	state: {},
	mutations: {},
	getters: {},
	actions: {
		async getEmailTemplates() {
			return axios.get(`/api/v1/administration/contact/templates`);
		},
		
		async sendEmailByTemplateName(ctx, {name, email}) {
			return axios.post(`/api/v1/administration/contact/template/${name}`,email);
		},
	},
};