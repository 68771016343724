import axios from "axios";

export default {
    namespaced: true,
    state: {},
    actions: {
        async getMetadataStats(ctx, { me = 1 }) {
            return axios.get(`/api/v1/storage/me/stats`);
        },
        async getSelfFiles(ctx, { start, size }) {
            return axios.get("/api/v1/storage/me/files", {
                params: {
                    start,
                    size,
                },
            });
        },
        async uploadFile(ctx, { file, name }) {
            let form = new FormData();
            form.append("file", file);
            form.append("name", name);

            return axios(
                Object.assign({
                    url: "/api/v1/storage/me/files",
                    method: "put",
                    data: form,
                    headers: { "Content-Type": "multipart/form-data" },
                })
            );
        },
        async deleteFile(ctx, { file }) {
            return axios.delete(`/api/v1/storage/me/files/${file}`);
        },
    },
    getters: {},
    mutations: {},
};
