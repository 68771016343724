<template>
    <v-row justify="space-around">
        <v-dialog v-model="isDialogShown" :persistent="persistent" :max-width="width">
            <template v-slot:default="dialog">
                <v-card>
                    <v-card-title class="align-center justify-center text-center" style="color: white;"
                        :style="{ 'background-color': titleBackgroudColor, 'color': titleFontColor, }">
                        <h4>{{ title }}</h4>
                    </v-card-title>
                    <v-card-text class="text-center">
                        <div class="pt-7" style="font-size: 18px; line-height: 22px;" :style="contentStyle"
                            v-html="content">

                        </div>
                    </v-card-text>
                    <v-card-actions class="pa-0 pb-5">
                        <v-spacer />
                        <BrebBtn backgroundColor="green" @click="buttonFunction">
                            {{ buttonContent }}
                        </BrebBtn>
                        <v-spacer v-if="secondButtonFunction !== null" />
                        <BrebBtn v-if="secondButtonFunction !== null" @click="secondButtonFunction"
                            backgroundColor="yellow" color="black">
                            {{ secondButtonContent }}
                        </BrebBtn>
                        <v-spacer />
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>
    </v-row>
</template>

<script>
import BrebBtn from "@/components/accessory/BrebBtn.vue"

export default {
    name: "BrebDialog",
    components: {
        BrebBtn,
    },
    props: {
        title: {
            type: String,
            required: false,
            default: "Notice"
        },
        titleBackgroudColor: {
            type: String,
            required: false,
            default: "orange"
        },
        titleFontColor: {
            type: String,
            required: false,
            default: "white"
        },
        content: {
            type: String,
            required: true,
        },
        buttonContent: {
            type: String,
            required: false,
            default: "Close"
        },
        buttonFunction: {
            type: Function,
            required: true,
        },
        secondButtonContent: {
            type: String,
            required: false,
            default: "Cancel"
        },
        secondButtonFunction: {
            type: Function,
            required: false,
            default: null,
        },
        isDialogShown: {
            type: Boolean,
            required: true,
            default: () => false,
        },
        width: {
            type: Number,
            required: false,
            default: () => 600,
        },
        persistent: {
            type: Boolean,
            required: false,
            default: () => true,
        },
        contentStyle: {
            type: Object,
            required: false,
        }
    },
    data() {
        return {

        }
    },
    computed: {
        cellphone() {
            return this.$vuetify.breakpoint.xs
        },
        mobile() {
            return this.$vuetify.breakpoint.mobile
        },
    },
    watch: {

    },
    methods: {

    },
    mounted() {

    }
}
</script>
<style scoped></style>

<style lang="scss">
@import '../../../node_modules/vuetify/src/styles/settings/variables';
@import "../../globals";

@media only screen and (max-width: map-get($grid-breakpoints, 'sm')) {}

@media only screen and (min-width: map-get($grid-breakpoints, 'sm')) {}
</style>